@import url(https://fonts.googleapis.com/css?family=Exo:400,700);
@media only screen and (max-height: 650px) {
  .content { top: 0; }
  .item { height:75px !important; width:75px !important; }
}



*{
    margin: 0px;
    padding: 0px;
}

body{
    background: #373da8;
    font-family: 'Exo', sans-serif;
}

a { 
    text-decoration: none; 
}

.context {
  width: 100%;
  position: absolute;
  top:35vh;
}

.context h1{
  text-align: center;
  color: #fff;
  font-size: 6vh;
}

.context h2{
  text-align: center;
  color: #fff;
  font-size: 5vh;
}

.item {
  transition: 0.1s;
  display: inline-block;
  text-align: left;
  margin: 0;
  width: 125px;
  height: 125px;
}

.item:hover {
  -webkit-animation: lifting 0.2s ease-in-out forwards;
          animation: lifting 0.2s ease-in-out forwards;
}

@-webkit-keyframes lifting {
  100% { -webkit-transform: translateY(-5px); transform: translateY(-5px); }
}

@keyframes lifting {
  100% { -webkit-transform: translateY(-5px); transform: translateY(-5px); }
}


/* Forcast meter */ 

#weather {
  position: fixed;
  z-index: 0;
  top: 2vh; 
  left: 1vw;
}

#weather_tag {
  background-color: white; 
  padding:15px; 
  border-radius:15px;
}

@media only screen and (max-width: 800px) {
  #weather {
      width:98vw;
  }
}
